import { prod as keycloak } from '../../../fleet-admin/src/environments/environment.keycloak';

const apiBase = "https://fleet.afd.badger-service.com";

export const environment = {
  envName: 'afd',
  production: false,
  apis: [
    {
      apiName: 'core',
      apiBase: apiBase,
      apiPath: 'api/web/v1'
    },
    {
      apiName: 'inspect',
      apiBase: apiBase,
      apiPath: 'api/cleansweep/v1',
    }
  ],
  apiBase: apiBase,
  apiPath: 'api/cleansweep/v1',
  ...keycloak
};
