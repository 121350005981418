import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { Viewport } from '../../models/viewport';
import { InsightImageComponent } from '../insight-image.component';
import { ViewportImagePosition } from '../../models/viewport-image';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'bt-viewport-thumb-selection',
  templateUrl: './viewport-thumb-selection.component.html',
  styleUrls: ['./viewport-thumb-selection.component.scss']
})

export class ViewportThumbSelectionComponent implements OnChanges {
  @Input() insightImageComponent: InsightImageComponent;
  @Input() viewport: Viewport;

  @ViewChild('viewportThumbLeft', { static: true }) viewportThumbLeftComponent: InsightImageComponent;
  @ViewChild('viewportThumbCenter', { static: true }) viewportThumbCenterComponent: InsightImageComponent;
  @ViewChild('viewportThumbRight', { static: true }) viewportThumbRightComponent: InsightImageComponent;

  imageSelection = new SelectionModel<InsightImageComponent>(false, []);

  ngOnChanges() {
    if (this.insightImageComponent) {
      this.setViewportImages(this.viewport);
    }
  }

  viewportThumbnailClicked(viewportThumbComponent: InsightImageComponent, position: ViewportImagePosition) {
    if (viewportThumbComponent.imageStatus === 'realized') {
      this.imageSelection.select(viewportThumbComponent);
      this.insightImageComponent.setInsightImage(this.viewport.getViewportImage(position));
    }
  }

  setViewportImages(viewport: Viewport) {
    if (viewport) {
      this.insightImageComponent.setInsightImage(viewport.getViewportImage(ViewportImagePosition.center));
      this.viewportThumbLeftComponent.setInsightImage(viewport.getViewportImage(ViewportImagePosition.left));
      this.viewportThumbCenterComponent.setInsightImage(viewport.getViewportImage(ViewportImagePosition.center));
      this.viewportThumbRightComponent.setInsightImage(viewport.getViewportImage(ViewportImagePosition.right));

      if (this.viewportThumbCenterComponent.imageStatus == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbCenterComponent, ViewportImagePosition.center);
      }
      else if (this.viewportThumbLeftComponent.imageStatus == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbLeftComponent, ViewportImagePosition.left);
      }
      else if (this.viewportThumbRightComponent.imageStatus == 'realized') {
        this.viewportThumbnailClicked(this.viewportThumbRightComponent, ViewportImagePosition.right);
      }
      else {
        this.insightImageComponent.setImageUrl(null);
      }
    }
    else {
      this.insightImageComponent.setImageUrl(null);
      this.viewportThumbLeftComponent.setImageUrl(null);
      this.viewportThumbCenterComponent.setImageUrl(null);
      this.viewportThumbRightComponent.setImageUrl(null);
    }
  }

  protected readonly ViewportImagePosition = ViewportImagePosition;
}
