import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthorizationService,
  BtCommonModule,
  CanDeactivateGuard,
  DeviceService,
  DialogService,
  ErrorService,
  InspectAuditService,
  MessageService,
  OrganizationSelectionService,
  OrganizationService,
  ReviewService,
  RoleGuard,
  StoreService,
  ThemeService,
  TitleService,
  UserService
} from '@ng-cloud/badger-core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ActionCableService } from 'angular2-actioncable';
import { HotkeyModule } from 'angular2-hotkeys';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LibLoginConfig, LibLoginInterceptor, LibLoginModule } from '@badgertechnologies/liblogin.js';

@NgModule({ exports: [
        BrowserAnimationsModule,
        BtCommonModule,
        HotkeyModule
    ],
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        CommonModule,
        BrowserAnimationsModule,
        BtCommonModule,
        HotkeyModule.forRoot({ cheatSheetCloseEsc: true }),
        {
            ngModule: LibLoginModule,
            providers: [
                {
                    provide: LibLoginConfig, useValue: {
                        fleet: environment.apiBase,
                        ...environment.keycloak
                    }
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: LibLoginInterceptor,
                    multi: true
                }
            ]
        }], providers: [
        ReviewService,
        StoreService,
        CanDeactivateGuard,
        RoleGuard,
        ActionCableService,
        MessageService,
        DialogService,
        ErrorService,
        ThemeService,
        TitleService,
        DeviceService,
        AuthorizationService,
        UserService,
        OrganizationService,
        InspectAuditService,
        OrganizationSelectionService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
