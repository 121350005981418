import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService, AuthorizationService, CustomIconService, ErrorService, MessageService, TitleService } from '@ng-cloud/badger-core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'inspect-review-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private resizeObservable: ResizeObserver;
  private _navMenuButton: MatButton;
  @ViewChild('navMenuTrigger') navMenuTrigger: MatMenuTrigger;

  @ViewChild('navMenuButton') set navMenuButton(navMenuButton: MatButton) {
    this._navMenuButton = navMenuButton;
    if (this._navMenuButton) {
      this.resizeObservable.observe(this._navMenuButton._elementRef.nativeElement);
    }
  }

  title = 'InSpect';

  constructor(
    public authService: AuthorizationService,
    private messageService: MessageService,
    private errorService: ErrorService,
    private customIconService: CustomIconService,
    private router: Router
  ) {
    TitleService.appTitle = this.title;
    ApiService.apis = environment.apis;

    this.customIconService.addCustomIcon('badger_logo', '../assets/badger_logo.svg');
    this.customIconService.addCustomIcon('inspect', '../assets/inspect.svg');
  }

  ngOnInit() {
    this.errorService.exceptions().subscribe(exception => {
      this.messageService.alert(exception.error, exception);
    });
    this.errorService.alerts().subscribe(alert => {
      this.messageService.alert(alert);
    });

    this.authService.currentRoles().subscribe(roles => {
      if (roles.includes('auditor') && !roles.includes('admin')) {
        // noinspection JSIgnoredPromiseFromCall
        this.router.navigate(['audit']);
      }
    });
  }

  ngOnDestroy(): void {
    this.resizeObservable && this.resizeObservable.disconnect();
  }

  ngAfterViewInit(): void {
    this.createResizeObservable();
  }

  createResizeObservable() {
    this.resizeObservable = new ResizeObserver(entries => {
      if (entries[0].contentRect.width === 0) {
        this.navMenuTrigger.closeMenu();
      }
    });
  }
}
